import React from "react"
import ContactForm from "./contact-form";
import images from "./images";
import SectionHeading from "./section-heading";

class PopupModel extends React.Component {

    render() {
        return (
            <>
                <div>
                    <div className="modal fade" id={this.props.name} tabIndex="-1" role="dialog"
                         aria-labelledby={this.props.name+'Label'} aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <button type="button" className="close form-close-btn mt-2 mr-3" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div style={{backgroundImage:'url('+images.greyWave+')'}}>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <div className="py-md-3 px-md-5">
                                                    <SectionHeading  text={"Enquire Now"} color={" theme-color-blue"}  />
                                                    <p className="fsm-theme-small-text">To enquire about our services please complete the form below and we will be in tough with you as soon as possible </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img src={images.customerCare} className="img-fluid" style={{maxHeight:'180px'}} />
                                            </div>
                                        </div>
                                    </div>
                                    <ContactForm modalId={this.props.name}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PopupModel
